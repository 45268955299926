/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UnitAvailability as UnitAvailabilitySection,
  UnitAvailabilityTypes,
  getGlobalAndFacilityEncKeyFromContext,
} from '@codeparva/gatsby-theme-layout-components';
import { LoaderComponent } from '@codeparva/gatsby-theme-tfw-1/src/components/loader/Loader';
import { UnitsContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/unitsContext';
import React, { useCallback, useContext } from 'react';
import Cookies from 'universal-cookie';
import { getMyOrigin } from '@codeparva/gatsby-theme-tfw-1/src/utils/origin';
import ErrorBoundary from '@codeparva/gatsby-theme-tfw-1/src/utils/ErrorBoundary';
import { getFacilityIdAndOriginToken } from '@codeparva/gatsby-theme-tfw-1/src/utils/TokenHelpers';
import { GlobalContext } from '@codeparva/gatsby-theme-tfw-1/src/components/Context/GlobalContext';
import { getFacilityId } from '@codeparva/gatsby-theme-tfw-1/src/components/account/config';
import { getTestEnvHeadersForProxyServer } from '@codeparva/gatsby-theme-tfw-1/src/utils/utils';
import { proxyServerAxiosInstance } from '@codeparva/gatsby-theme-tfw-1/src/utils/apiHelpers';
import { SessionTimerContext } from '@codeparva/gatsby-theme-tfw-1/src/components/Context/SessionTimerContext';
import {
  clearContext,
  clearCookies,
  logOutUser,
} from '@codeparva/gatsby-theme-tfw-1/src/utils/LogoutHelper';
import { getCurrentLocation } from '@codeparva/gatsby-theme-tfw-1/src/utils/locationHelpers';
import { fetchSmsConfigurations } from '@codeparva/v11-tfw-api-library';
import { FacilityContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/facilityContext';

export type TUnitAvailabilityConfig = {
  strapiUnitAvailabilityConfig?: any;
  handleScroll: (node: any) => void;
  strapiWhatWeOfferConfig: any;
  pageContext: any;
};

export const UnitAvailability = React.forwardRef(
  (
    {
      strapiUnitAvailabilityConfig,
      handleScroll,
      strapiWhatWeOfferConfig,
      pageContext,
    }: TUnitAvailabilityConfig,
    ref: any,
  ) => {
    const globalContext = useContext(GlobalContext);
    const facilityContext = useContext(FacilityContext);
    const sessionContext = React.useContext(SessionTimerContext);
    globalContext?.setFacilityId(pageContext.facilitySmsId);
    const origin = getMyOrigin();
    const context = React.useContext(UnitsContext);
    const facilityId = getFacilityId(pageContext.facilitySmsId);
    const [apiRes, setApiRes] = React.useState<any>();
    const [loader, showLoader] = React.useState(true);

    const { facPublicKey } = getGlobalAndFacilityEncKeyFromContext(facilityContext, facilityId);

    // const cookieExpiryTime = process.env.GATSBY_COOKIE_EXPIRY_TIME || expiryTime;
    // const setExpiryTime = (cookieExpiryTime: string) => {
    //   const now = new Date();
    //   now.setMinutes(now.getMinutes() + parseInt(cookieExpiryTime, 10));
    //   const expires = now;
    //   return expires;
    // };

    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + getFacilityIdAndOriginToken(facilityId, origin),
        'facility-id': facilityId,
        'strapi-facility-id': process.env.GATSBY_STRAPI_FACILITY_ID,
        'location-id': pageContext.locationStrapiId,
        ...getTestEnvHeadersForProxyServer(),
      },
    };

    const selectedLocation = getCurrentLocation(pageContext?.allAvailableLocations, facilityId);

    const logOut = () => {
      clearContext(globalContext, sessionContext);
      clearCookies();
      logOutUser(false);
    };

    const facilityIdDetails = {
      selectedFacilityId: facilityId,
      loggedInFacilityId: globalContext?.loggedInSmsFacilityId,
    };

    const replaceCustomArt = (unitTypeRes: UnitAvailabilityTypes.TUnitTypesRes[]) => {
      const UnitAvailabilityRes = new Map(
        strapiUnitAvailabilityConfig?.customArtGatsby?.map((item: { unitTypeID: string }) => [
          item.unitTypeID,
          item,
        ]),
      );
      unitTypeRes.map((item: { unitTypeID: unknown; customArt: any }) => {
        const items: any = UnitAvailabilityRes.get(item.unitTypeID);
        if (items && items.customArt) {
          item.customArt = items.customArt;
        }
      });
      return unitTypeRes;
    };

    React.useEffect(() => {
      async function fetchAPI() {
        const cookies = new Cookies();

        // TODO: To move this to api-library package, the data merge logic in proxy has to be shifted here
        const res = await proxyServerAxiosInstance.post(
          '/units',
          { facilityName: pageContext.facilityDetails.facility_name },
          options,
        );
        const [
          _,
          {
            data: {
              data: {
                Tenant_Websites: {
                  data: [
                    {
                      value: {
                        is_bev_enabled: bevStatus = false,
                        payment: {
                          is_credit_card_payment_enabled = false,
                          is_ach_payment_enabled = false,
                        } = {},
                      } = {},
                    } = {},
                  ] = [],
                } = {},
              } = {},
            } = {},
          } = {},
        ] = await fetchSmsConfigurations({
          configTypes: ['Tenant_Websites'],
          facilityId,
          facPublicKey,
        });

        const isPaymentEnabled = is_credit_card_payment_enabled || is_ach_payment_enabled;
        setApiRes({ ...res.data.response.data, bevStatus, isPaymentEnabled });
        context?.setUnitsData(res.data.response.data);
        context?.setIndividualunitsData(res.data.response.data.unitTypeData[0]);
        cookies.set('unitsAvailability', true, {
          path: '/',
        });

        showLoader(false);
      }

      try {
        if (loader) {
          fetchAPI();
        } else {
          handleScroll(ref);
        }
      } catch (error) {
        console.log(error);
      }
    }, [loader]);

    const wrapper = useCallback((node) => {
      if (node !== null) {
        ref.current = node;
      }
    }, []);

    const UnitAvailabilitySectionComponent = () => {
      const updatedRes = replaceCustomArt(apiRes.unitTypeData);
      const sortedUnits = updatedRes.sort((a, b) => {
        const value1 = a.unitTypeName.toLowerCase();
        const value2 = b.unitTypeName.toLowerCase();
        if (value1 < value2) {
          return -1;
        }
        if (value1 > value2) {
          return 1;
        }
        return 0;
      });
      apiRes.unitTypeData = sortedUnits;
      return (
        <UnitAvailabilitySection
          res={apiRes}
          UnitAvailabilityConfig={strapiUnitAvailabilityConfig}
          onSetIndividualData={context?.setIndividualunitsData}
          WhatWeOfferConfig={strapiWhatWeOfferConfig}
          locationStrapiId={pageContext.locationStrapiId}
          facilityName={pageContext.facilityDetails.facility_name}
          facilityIdDetails={facilityIdDetails}
          logOutHandler={logOut}
          globalContext={globalContext}
          selectedLocation={selectedLocation}
        />
      );
    };

    const selectUnitAvailabilityComponent = () => {
      if (loader) {
        return <LoaderComponent />;
      }
      return <UnitAvailabilitySectionComponent />;
    };

    return (
      <div ref={wrapper} className="unitCardWrapper is-flex">
        <ErrorBoundary>{selectUnitAvailabilityComponent()}</ErrorBoundary>
      </div>
    );
  },
);
